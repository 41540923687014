.LessonChallenges__progress-bar {
  @apply bg-teal-2 w-full mb-1 rounded;
  height: 4px;
}

.LessonChallenges__progress {
  @apply bg-purple-6 h-full rounded ease-linear transition-all duration-500;
  width: 0%;
}

.LessonChallenges__progress-percentage {
  @apply mx-auto text-xs text-gray-4;
}
