.NodeTree {
  @apply bg-white h-screen overflow-hidden shadow relative grid-rows-1 z-10;
  width: 240px;
  transition: width .5s linear;
}

.NodeTree__buttons-wrapper {
  @apply px-2 flex;
  width: 240px;
  transition: width .5s linear;
}

.NodeTree__graph {
  @apply overflow-hidden;
}

.NodeTree__toggle {
  @apply flex h-10 w-10 absolute right-1 bottom-1 rounded-full;
  transition: transform .5s, background-color .2s;
  transform: rotate(-90deg);
  transition-timing-function: linear;
}

.NodeTree__toggle:hover {
  @apply bg-gray-100;
}

.NodeTree--closed .NodeTree__toggle {
  transform: rotate(-270deg);
}

.NodeTree__buttons {
  transition: background-color .2s, opacity .2s;
}

.NodeTree__graph {
  transition: opacity .2s;
  transition-timing-function: linear;
}

.NodeTree--closed {
  @apply w-12 overflow-hidden;
}

div.vis-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 5px;
  white-space: nowrap;

  font-family: 'Inter';
  font-size: 14px;
  color:#23262C;
  background-color: #FAFAFC;

  -moz-border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid #23262C;

  box-shadow: 3px 3px 10px rgba(35, 38, 44, 0.2);
  pointer-events: none;

  z-index: 15;
}
