.Markdown img {
  @apply mx-auto max-h-72;
}

.Markdown ul,
.Markdown ol,
.Markdown p,
.Markdown h1,
.Markdown h2,
.Markdown h3,
.Markdown h4,
.Markdown h5,
.Markdown h6,
.Markdown img,
.Markdown details {
  @apply mb-4;
}

.Markdown summary > * {
  @apply mb-0 inline-block;
}

.Markdown table,
.Markdown pre {
  @apply mb-6;
}
