html, body {
  font-family: 'Inter', sans-serif;
  color: #23262C;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-weight: bold;
  font-style: normal;
}

h1, .h1 {
  font-size: 32px;
}

h2, .h2 {
  font-size: 24px;
}

h3, .h3 {
  font-size: 20px;
}

h4, .h4 {
  font-size: 16px;
}

h5, .h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

h6, .h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

p, div {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

p > code, details > code {
  @apply bg-gray-1 rounded border border-gray-2;
  padding: 2px;
}

a {
  @apply text-purple-6 font-semibold;
}

a:hover {
  @apply underline;
}

hr {
  @apply my-4;
}

ul, ol {
  @apply list-inside;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

pre {
  @apply p-4 rounded filter drop-shadow-lg bg-gray-0 mx-auto w-11/12;
  background-color: #fff;
}

table {
  @apply table-auto mx-auto w-11/12;
}

table, th, td {
  @apply border-collapse border border-solid;
}

summary {
  @apply cursor-pointer;
}

blockquote {
  @apply p-3 border-l-4 text-gray-4 border-gray-2 mb-4;
}

blockquote > * {
  margin-bottom: 0px !important;
}

::-webkit-scrollbar-track-piece {
	background-color: #fff;
	-webkit-border-radius: 0;
}
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	height: 50px;
	background-color: #999;
	-webkit-border-radius: 4px;
	outline: 2px solid #fff;
	outline-offset: -2px;
	border: 2px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
	height: 50px;
	background-color: #9f9f9f;
	-webkit-border-radius: 4px;
}

.introjs-tooltiptext {
  @apply flex flex-col;
}

.introjs-hint-dot {
  z-index: 20 !important;
  border: 10px solid #4054C7 !important;
}

.introjs-hint-pulse {
  border: 5px solid rgba(141, 153, 227, .3) !important;
  background-color: rgba(188, 196, 247, .3) !important;
}

.introjs-button {
  @apply mt-2;
  width: fit-content;
}

@media (max-width: 767px) {
  .introjs-overlay, .introjs-helperLayer, .introjs-tooltipReferenceLayer {
    display: none !important;
  }
}
