.Lesson {
  @apply grid h-screen grid-rows-1;
  grid-template-columns: auto minmax(0, 1fr);
}

.Lesson__content {
  @apply flex p-6 gap-6;
  transition: gap .4s;
  transition-timing-function: linear;
}

.Lesson__content--no-gap {
  @apply gap-0;
}

.LessonChallenges, .LessonTextBook {
  @apply grid overflow-hidden bg-white shadow rounded relative;
  transition: width .4s;
  transition-timing-function: linear;
}

.LessonChallenges__content, .LessonTextBook__content {
  @apply grid p-6 overflow-auto;
  transition: opacity .2s;
  transition-timing-function: linear;
}

.LessonTextBook__content {
  grid-template-rows: auto minmax(0, 1fr);
}

.LessonChallenges__content {
  grid-template-rows: auto minmax(0, 1fr) auto;
}

.LessonTextBook__show-challenges, .LessonTextBook__show-textbook {
  @apply bg-white hover:bg-purple-1 text-purple-6 font-bold py-2 px-4 rounded my-auto ml-auto;
  transition: opacity .2s, background-color .2s;
  transition-timing-function: linear;
}

.close-button {
  @apply flex absolute right-2 top-2 transition-all duration-500;
}
